import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { mediaQuery } from 'src/styles'
import { orderServices } from 'src/utils'

export const ServiceList: React.FC = () => {
  return (
    <StaticQuery<GatsbyTypes.ServiceList>
      query={graphql`
        query ServiceList {
          allMarkdownRemark(filter: { fields: { slug: { regex: "^/service/" } } }) {
            edges {
              node {
                fields {
                  slug
                }
                id
                frontmatter {
                  slug
                  title
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 600, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const serviceList = data.allMarkdownRemark.edges
        // 並び替え
        orderServices(serviceList)
        return (
          !!serviceList && (
            <ScWrapper>
              {serviceList.map((v: any) => (
                <ScItem key={v.node.id}>
                  <SCLink to={v.node.fields.slug}>
                    {!!v.node.frontmatter.featuredImage && (
                      <Img fluid={v.node.frontmatter.featuredImage.childImageSharp.fluid} alt="" />
                    )}
                    <ScText>{v.node.frontmatter.title}</ScText>
                  </SCLink>
                </ScItem>
              ))}
            </ScWrapper>
          )
        )
      }}
    />
  )
}

const ScWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery('sm')} {
    flex-wrap: nowrap;
  }
`

const ScItem = styled.div`
  padding: 8px;
  width: 50%;
  ${mediaQuery('sm')} {
    width: 25%;
  }
`

const SCLink = styled(Link)`
  color: ${({ theme }) => theme.color.text.main};
`

const ScText = styled.p`
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 0.5em;
  ${mediaQuery('sm')} {
    font-size: 1rem;
  }
`
