import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'

type Props = {
  src: string
} & ComponentStyleProps

export const FeaturedImage: React.FC<Props> = (props) => {
  const { src } = props
  return (
    <ScFeaturedImage>
      <img src={src} alt="" />
    </ScFeaturedImage>
  )
}

const ScFeaturedImage = styled.div`
  margin-right: -1rem;
  margin-left: -1rem;
  margin-bottom: 1.5rem;
  height: 20vh;
  overflow: hidden;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  ${mediaQuery('sm')} {
    margin-right: -2rem;
    margin-left: -2rem;
    margin-bottom: 2.5rem;
  }
`
