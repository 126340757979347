/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Img from 'gatsby-image'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'
import Arrow from 'src/images/icons/icon-arrow-right.svg'
import { v4 as uuid } from 'uuid'

type Props = {
  flow: {
    icon: any
    title: string
    description?: string
  }[]
} & ComponentStyleProps

export const Flow: React.FC<Props> = (props) => {
  const { flow } = props

  return (
    <ScFlowWrapper>
      {flow.map((v, i) => (
        <React.Fragment key={uuid()}>
          <ScFlow>
            <ScIcon>
              <Img fluid={v.icon.childImageSharp.fluid} alt="" />
            </ScIcon>
            <ScTitle>{v.title}</ScTitle>
            {!!v.description && <ScDescription>{v.description}</ScDescription>}
          </ScFlow>
          {i < flow.length - 1 && <ScArrow src={Arrow} />}
        </React.Fragment>
      ))}
    </ScFlowWrapper>
  )
}

const ScFlowWrapper = styled.div`
  ${mediaQuery('sm')} {
    display: flex;
    justify-content: center;
  }
`

const ScFlow = styled.div`
  background-color: #f7f7f7;
  border-radius: 12px;
  margin: 0 auto;
  padding: 24px;
  width: 240px;
  ${mediaQuery('sm')} {
    width: 20%;
  }
`

const ScIcon = styled.div`
  margin: 0 auto;
  width: 96px;
  ${mediaQuery('sm')} {
    width: 120px;
  }
`

const ScTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  ${mediaQuery('sm')} {
    font-size: 1.125rem;
  }
`

const ScDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5em;
  ${mediaQuery('sm')} {
    font-size: 0.875rem;
  }
`

const ScArrow = styled.img`
  display: block;
  margin: 32px auto;
  transform: rotate(90deg);
  width: 40px;
  ${mediaQuery('sm')} {
    margin: 0 16px;
    transform: rotate(0);
  }
`
