import React from 'react'
import { graphql } from 'gatsby'
import styled, { mediaQuery } from 'src/styles'
import { PageHeading, Heading } from 'src/components/molecules/Heading'
import { Inner } from 'src/components/layouts/Inner'
import { PageDescription } from 'src/components/domains/pages/PageDescription'
import { ServicePointWrapper, ServicePoint } from 'src/components/domains/service/ServicePoint'
import { LinkButton, ButtonWrapper } from 'src/components/domains/app/Button'
import { ServiceList } from 'src/components/domains/service/ServiceList'
import { Flow } from 'src/components/molecules/Flow'
import { ElementContainer } from 'src/components/layouts/ElementContainer'
import { v4 as uuid } from 'uuid'
import { useSiteMetadata } from 'src/hooks'
import { FeaturedImage } from 'src/components/domains/service/FeaturedImage'
import PageMeta from 'src/components/PageMeta'
import Layout from '../components/Layout'

type PageProps = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
        titleEn: string
        descriptionSeo?: string
        slug: string
        description: string
        featuredImage?: any
        serviceDetailWorries?: {
          text: string
        }[]
        serviceDetailFeature?: {
          text: string
        }[]
        flow: any
      }
    }
  }
}

type Props = {
  title: string
  titleEn: string
  slug: string
  description: string
  serviceDetailWorries?: {
    text: string
  }[]
  serviceDetailFeature?: {
    text: string
  }[]
  flow?: {
    icon: any
    title: string
  }[]
}

export const ServiceDetailPageTemplate: React.FC<Props> = (props) => {
  const { title, titleEn, slug, description, serviceDetailWorries, serviceDetailFeature, flow } = props

  return (
    <>
      <ScPageHeading component="h2" heading={title} subHeading={titleEn} />

      <ScDescription>{description}</ScDescription>

      <ScHeading>こんなことで悩んでいませんか？</ScHeading>
      <ScServicePointWrapper>
        {!!serviceDetailWorries &&
          serviceDetailWorries.map((v) => <ServicePoint variant="negative" text={v.text} key={uuid()} />)}
      </ScServicePointWrapper>

      <ScHeading>サービスの特徴</ScHeading>
      <ScServicePointWrapper>
        {!!serviceDetailFeature &&
          serviceDetailFeature.map((v) => <ServicePoint variant="positive" text={v.text} key={uuid()} />)}
      </ScServicePointWrapper>

      <ScHeading>サービス導入のフロー</ScHeading>
      <ElementContainer>{!!flow && <Flow flow={flow} />}</ElementContainer>

      <ElementContainer>
        <ButtonWrapper align="center">
          <LinkButton to={`/contact?ref=${slug}`}>お問い合わせ</LinkButton>
        </ButtonWrapper>
      </ElementContainer>

      <ServiceList />
    </>
  )
}

// CSS in JS
const ScPageHeading = styled(PageHeading)`
  margin-bottom: 1.25rem;
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
  }
`

const ScDescription = styled(PageDescription)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`

const ScHeading = styled(Heading)`
  margin-bottom: 1em;
`

const ScServicePointWrapper = styled(ServicePointWrapper)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`
// End CSS in JS

const ServiceDetailPage: React.FC<PageProps> = ({ data }) => {
  const { siteTitle } = useSiteMetadata()
  const {
    title,
    titleEn,
    slug,
    description,
    descriptionSeo,
    featuredImage,
    serviceDetailWorries,
    serviceDetailFeature,
    flow,
  } = data.markdownRemark.frontmatter

  return (
    <Layout hasFeaturedImage>
      <PageMeta title={`${title} | ${siteTitle}`} description={descriptionSeo} />
      <FeaturedImage src={featuredImage.childImageSharp.fluid.src} />
      <Inner>
        <ServiceDetailPageTemplate
          title={title}
          titleEn={titleEn}
          slug={slug}
          description={description}
          serviceDetailWorries={serviceDetailWorries}
          serviceDetailFeature={serviceDetailFeature}
          flow={flow}
        />
      </Inner>
    </Layout>
  )
}

export default ServiceDetailPage

export const servicePageQuery = graphql`
  query ServiceDetailPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleEn
        descriptionSeo
        slug
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        serviceDetailWorries {
          text
        }
        serviceDetailFeature {
          text
        }
        flow {
          icon {
            childImageSharp {
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
      }
    }
  }
`
