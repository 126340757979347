import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import WorriedFace from 'src/images/icons/icon-worried-face.svg'
import SmilingFace from 'src/images/icons/icon-smiling-face.svg'
import { ComponentStyleProps } from 'src/types'

type Props = {
  variant: 'negative' | 'positive'
  text: string
} & ComponentStyleProps

export const ServicePointWrapper: React.FC = (props) => {
  const { children } = props
  return <ScWrapper {...props}>{children}</ScWrapper>
}

export const ServicePoint: React.FC<Props> = (props) => {
  const getIcon = (variant: Props['variant']) => {
    switch (variant) {
      case 'negative':
        return WorriedFace
      case 'positive':
        return SmilingFace
      default:
        return null
    }
  }

  const { variant, text } = props

  return (
    <ScServicePoint>
      <ScIcon variant={variant}>
        <img src={getIcon(variant)} alt="" />
      </ScIcon>
      <ScText>{text}</ScText>
    </ScServicePoint>
  )
}

const ScWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  ${mediaQuery('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ScServicePoint = styled.div`
  background-color: ${({ theme }) => theme.color.background.gray};
  display: flex;
`

const ScIcon = styled.div<{ variant: Props['variant'] }>`
  ${({ theme, variant }) =>
    !!variant &&
    `
    background-color: ${variant === 'positive' ? theme.color.creaith.green : theme.color.creaith.red};
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  padding: 10px;
  img {
    height: 20px;
    width: 20px;
  }
  ${mediaQuery('sm')} {
    width: 80px;
    padding: 20px;
    img {
      height: 40px;
      width: 40px;
    }
  }
`

const ScText = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px;
  ${mediaQuery('sm')} {
    font-size: 1rem;
  }
`
