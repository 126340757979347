import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'

type Props = ComponentStyleProps

export const ElementContainer: React.FC<Props> = (props) => {
  const { children } = props
  return <ScElementContainer {...props}>{children}</ScElementContainer>
}

const ScElementContainer = styled.div`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`
